import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

type variantType = 'success' | 'error' | 'warning' | 'info'

export const useSnackbarMessages = () => {
  const snackbar = useSnackbar()

  const addMessage = useCallback(
    (message: string, variant: variantType) => {
      snackbar.enqueueSnackbar(message, {
        variant,
      })
    },
    [snackbar],
  )

  const addSuccessMessage = useCallback(
    (message: string) => {
      addMessage(message, 'success')
    },
    [addMessage],
  )

  const addErrorMessage = useCallback(
    (message: string) => {
      addMessage(message, 'error')
    },
    [addMessage],
  )

  const addWarningMessage = useCallback(
    (message: string) => {
      addMessage(message, 'warning')
    },
    [addMessage],
  )

  const addInfoMessage = useCallback(
    (message: string) => {
      addMessage(message, 'info')
    },
    [addMessage],
  )

  return {
    addMessage,
    addSuccessMessage,
    addErrorMessage,
    addWarningMessage,
    addInfoMessage,
  }
}
