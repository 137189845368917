import moment from 'moment'
import * as DateFns from 'date-fns'

export enum DateFilterType {
  today = 'today',
  week = 'week',
  month = 'month',
  year = 'year',
}

export const timePassedFromNow = (date: Date) => {
  return moment(date).fromNow(true)
}

export const timeLeftToDate = (date: Date) => {
  return moment().to(moment(date))
}

export const timePassedToNow = (date: Date) => {
  return moment(date).toNow(true)
}

export const localDate = (date: Date) => {
  if (!date) {
    return '-'
  }
  return moment(date).format('l')
}

export const localWeekDayWithDate = (date: Date) => {
  return moment(date).format('ddd, MMM D')
}

export const formatLocalDateTime = (date: Date) => {
  if (!date) {
    return null
  }
  return moment(date).format('lll')
}

export const formatLocalDate = (date: Date) => {
  if (!date) {
    return null
  }
  return moment(date).format('l')
}

export const formatLocalTime = (date: Date) => {
  if (!date) {
    return null
  }
  return moment(date).format('hh:mm a')
}

/*
export const removeTimeFromDate = (date?: Date) => {
  let val: Date = new Date()
  if (date) {
    val = date
  }
  const dateStr = moment(val).local().format('YYYY-MM-DD') + ' 00:00:00'
  return new Date(dateStr)
}
*/

export const removeTimeFromDate = (date?: Date) => {
  let val: Date = new Date()
  if (date) {
    val = date
  }
  const offset = moment(val).format('Z')
  const dateStr =
    moment(val).local().format('YYYY-MM-DD') + 'T00:00:00' + offset
  return new Date(dateStr)
}

export const mergeDateAndTime = (date: Date, time: Date) => {
  const offset = moment(date).format('Z')
  const dateStr = moment(date).local().format('YYYY-MM-DD')

  const timeStr = moment(time).local().format('HH:mm:ss')

  const dateTimeStr = dateStr + 'T' + timeStr + offset
  return new Date(dateTimeStr)
}

export const isDateAfterNow = (date: Date) => {
  return moment(date).isAfter(new Date())
}

export const isDateBeforeNow = (date: Date, ignoreTime: boolean = true) => {
  const val = ignoreTime ? removeTimeFromDate(date) : date
  const today = ignoreTime ? removeTimeFromDate(new Date()) : new Date()
  return moment(val).isBefore(today)
}

export const dateFilterNameToDateRange = (
  filterName: DateFilterType,
): [Date, Date] => {
  const rightNow = new Date()
  switch (filterName) {
    case DateFilterType.today:
      return [DateFns.startOfToday(), DateFns.endOfToday()]
    case DateFilterType.week:
      return [DateFns.startOfWeek(rightNow), DateFns.endOfWeek(rightNow)]
    case DateFilterType.month:
      return [DateFns.startOfMonth(rightNow), DateFns.endOfMonth(rightNow)]
    case DateFilterType.year:
      return [DateFns.startOfYear(rightNow), DateFns.endOfYear(rightNow)]
  }

  return null
}
